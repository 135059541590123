<template>
  <svg width="39" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 23c.483 1.448 2.257 1.759 4 4 7 9 7.092 13 11 13 3.826 0 5.379-8.3 10-17 5.043-9.492
             13-19.473 13-20 0-2-1-3-3-2-1.333.667-8.667 9.333-22
             26-4.958-4.954-7.958-7.621-9-8-1.563-.568-5 1-4 4z"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
</template>
